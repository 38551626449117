<template>
    <v-container fluid>
        <!-- JustDial Integration Page -->
        <v-card class="rounded d-flex flex-row flex-lg-wrap">
            <v-row class="ma-0">
                <v-col>
                    <v-card color="transparent" elevation="0" style="padding: 10px; text-align: center !important">
                        <v-card-text style="margin-bottom: 25px">
                            <div align="center" style="margin-bottom: 10px">
                                <h1 style="margin-bottom: 40px">GoDial + JustDial</h1>
                            </div>
                            <div style="padding: 5px 15px; text-align: center">
                                <div style="text-align: left">
                                    <p
                                        style="margin-top: 20px; font-size: 18px; font-weight: 600; margin-bottom: 10px;">
                                        How to integrate JustDial with GoDial?
                                    </p>
                                    <p>
                                        1. Create a list in GoDial. Goto CRM > New list
                                        <br />
                                        2. Connect your JustDial account with GoDial by selecting
                                        the list where you want to receive leads.
                                        <br />
                                        3. Copy the cURL, and provide it to your
                                        JustDial account manager to enable the integration.
                                        <br />
                                        4. Once activated, leads from JustDial will flow into
                                        GoDial automatically in the assigned list.
                                    </p>
                                </div>
                            </div>
                        </v-card-text>
                        <v-footer color="transparent" absolute>
                            <v-flex style="text-align: center !important">
                                <p style="font-size: 12px; opacity: 0.9; margin-bottom: 0px;">
                                    Feel free to reach out to us at
                                    <a href="mailto:support@godial.cc">support@godial.cc</a>
                                    if you are interested in these features
                                </p>
                            </v-flex>
                        </v-footer>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card flat>
                        <v-flex xs12 sm10 offset-sm1 md10 offset-md1>
                            <v-layout row wrap>
                                <v-flex d-flex justify-center class="mt-10" style="padding-top: 22px">Enable JustDial
                                </v-flex>
                                <v-flex class="mt-10" d-flex justify-center>
                                    <v-switch v-model="justdial.enabled" color="primary"></v-switch>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-card>
                    <v-card color="transparent" elevation="0"
                        style="margin-top:25px; padding: 15px 50px; text-align: center">
                        <v-card-title primary-title class="justify-center">JustDial Credentials</v-card-title>

                        <v-flex xs12 sm12 offset-sm1 md12 offset-md1>
                            <v-autocomplete v-model="justdial.selectedList" :disabled="!justdial.enabled" class="pt-1"
                                :items="lists" item-text="name" item-value="id" label="Select List" return-object
                                prepend-inner-icon="view_list" autocomplete="false" outlined dense />
                        </v-flex>

                        <v-flex xs12 sm12 lg12 offset-sm1 md12 offset-md1 style="margin-bottom: 10px">
                            <v-btn color="primarygrad" dark block @click="setJustDial">Save</v-btn>
                        </v-flex>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-card v-if="justdial.enabled && justdial.selectedList.id" class="integration-card my-3 pa-2">
            <v-alert color="primary" icon="mdi-alert" theme="dark" prominent class="mt-2">
                Please copy these details and send over to your JustDial account
                manager via email. Once they activate the subscription, leads will start
                coming to the list selected by you.
            </v-alert>
            <v-card-title primary-title class="integration-title">
                Activate Integration
            </v-card-title>

            <v-card-text class="integration-body">
                <v-row>
                    <v-col>
                        <v-card flat class="integration-details">
                            <!-- Endpoint -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Endpoint:</p>
                                    <p class="integration-value">
                                        {{ ENDPOINT }}/externals/justdial?access_token={{ token }}
                                    </p>
                                </v-col>
                            </v-row>

                            <!-- Method -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Method:</p>
                                    <p class="integration-value">POST</p>
                                </v-col>
                            </v-row>

                            <!-- Headers -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Headers:</p>
                                    <p class="integration-value">
                                        Content-Type: application/json
                                    </p>
                                </v-col>
                            </v-row>

                            <!-- Body -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">Params:</p>
                                    <v-textarea
                                        :value="`?access_token=${token}&queryName=John Wick&queryEmail=john12%40gmail.com&queryPhone=9874563210&queryCity=Kolkata&queryState=West Bengal&queryRemarks=I want service at my location`"
                                        readonly outlined dense class="integration-textarea">
                                    </v-textarea>
                                </v-col>
                            </v-row>

                            <!-- cURL -->
                            <v-row>
                                <v-col>
                                    <p class="integration-label">cURL:</p>
                                    <v-textarea ref="curlTextarea"
                                        :value="`curl --location --request POST '${ENDPOINT}/externals/justdial?access_token=${token}&queryName=John Wick&queryEmail=john12%40gmail.com&queryPhone=9874563210&queryCity=Kolkata&queryState=West Bengal&queryRemarks=I want service at my location`"
                                        readonly outlined dense block class="integration-textarea">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row class="my-0">
                                <v-col>
                                    <v-btn color="primary" @click="copyToClipboard">
                                        Copy to Clipboard
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "../../js/firebase";

const _ = require("lodash");

export default {
    data() {
        return {
            justdial: {
                enabled: false,
                selectedList: {},
            },
            lists: [],
            token: "",
        };
    },

    computed: {
        ...mapGetters(["ENDPOINT", "companyDetails", "selectedTeam"]),
    },
    async mounted() {
        this.generateToken();
        await this.fetchLists();
        await this.getIntegrationDetails();
    },
    methods: {
        copyToClipboard() {
            const curlCommand = this.$refs.curlTextarea.internalValue;
            const message = `Hello,\n\nWe request you to enable the CRM API for real-time leads in GoDial.\n\nBelow is the endpoint and example:\n\n`;
            const fullTextToCopy = message + curlCommand;
            navigator.clipboard.writeText(fullTextToCopy).then(() => {
                this.$swal({ type: "success", text: "Copied to clipboard!" });
            }).catch((err) => {
                console.error("Failed to copy!", err);
            });
        },
        async setJustDial() {
            try {
                let resp = await this.$http.get(
                    `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=justdialv2`
                );
                let respo;

                if (resp.data && resp.data.length && resp.data[0].id) {
                    respo = await this.$http.patch(`${this.ENDPOINT}/integrations/${resp.data[0].id}`, {
                        config: {
                            listId: this.justdial.selectedList.id,
                        },
                    });
                } else {
                    respo = await this.$http.post(`${this.ENDPOINT}/integrations`, {
                        type: "justdialv2",
                        enabled: true,
                        companyId: this.companyDetails.id,
                        config: {
                            listId: this.justdial.selectedList.id,
                        },
                    });
                }

                if (respo.data && respo.data.id) {
                    this.$swal({
                        type: "success",
                        text: "Configuration Saved Successfully",
                    });
                } else {
                    this.$swal({
                        type: "error",
                        text: "An error occurred! Please try again.",
                    });
                }
            } catch (error) {
                console.log("JustDial integration setting error: ", error);
                this.$swal({
                    type: "error",
                    text: error.response && error.response.data && error.response.data.error
                        ? error.response.data.error.message
                        : "Unknown error occurred.",
                });
            }
        },
        async fetchLists() {
            try {
                this.lists = [];
                let teamIds = this.getTeamIds();
                let response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
                    teams: teamIds,
                });
                this.lists = response.body;

                if (this.lists.length < 1) {
                    this.loader = false;
                }
            } catch (e) {
                console.log("Error in list fetch", e);
            }
        },
        async getIntegrationDetails() {
            try {
                let response = await this.$http.get(
                    `${this.ENDPOINT}/integrations?filter[where][companyId]=${this.companyDetails.id}&filter[where][type]=justdialv2`
                );
                if (response.data && response.data.length && response.data[0].id) {
                    this.justdial.enabled = response.data[0].enabled;
                    this.justdial.selectedList = _.find(this.lists, ["id", response.data[0].config.listId]);
                }
            } catch (error) {
                console.error("JustDial integration details error:", error);
            }
        },
        generateToken() {
            this.$http
                .get(
                    `${this.ENDPOINT}/externals/token?access_token=${this.$store.getters.user.token}`
                )
                .then((response) => {
                    this.token = response.body.id;
                })
                .catch((err) => {
                    this.$swal({ type: "error", text: err.body.error.message });
                });
        },
        getTeamIds() {
            let teams = [];
            if (this.selectedTeam == "allTeams") {
                teams = _.map(this.teams, "id");
            } else {
                teams.push(this.selectedTeam);
            }
            return _.without(teams, "allTeams");
        },
    },
};
</script>

<style scoped>
.integration-label {
    font-size: 14px;
    font-weight: bold;
}

.integration-value {
    font-size: 16px;
}

.integration-title {
    font-size: 24px;
    font-weight: bold;
}

.integration-body {
    padding: 20px;
}

.integration-textarea {
    min-height: 80px;
}
</style>
